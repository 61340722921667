@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    overflow-y: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


h1, h2, h3, h4, h5, h6 {

    font-style: normal;
}

#root {
    font-family: 'Montserrat', sans-serif;
    min-height: 100vh;
}